import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexComponent } from './index/index.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { PagesComponent } from './pages/pages.component';
import {  CertificatesComponent } from './certificates/certificates.component';
import { EnquiryComponent } from './enquiry/enquiry.component';
import { CareerComponent } from './career/career.component';
import { TradeFairComponent } from './trade-fair/trade-fair.component';
import { ProductsComponent } from './products/products.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
const routes: Routes = [

	{ path: 'about-us', component: PagesComponent,data : {PageId : 3} },
	{ path: 'contact-us', component: PagesComponent,data : {PageId : 4}},
	{ path: 'organic-seeds', component: PagesComponent,data : {PageId : 5}},
	{ path: 'infrastructure', component: PagesComponent,data : {PageId : 6}},
	{ path: 'private-labeling', component: PagesComponent,data : {PageId : 7}},
	{ path: 'certificates', component: CertificatesComponent },
	{ path: 'career', component: CareerComponent },
	{ path: 'products', component: ProductsComponent },
	{ path: 'oilseeds', component: ProductsComponent,data:{ CategoryId : 2} },
	{ path: 'spices', component: ProductsComponent,data:{ CategoryId : 3}},
	{ path: 'grains', component: ProductsComponent,data:{ CategoryId : 4}},
	{ path: 'enquiry', component: EnquiryComponent },
	{ path: 'trade-fair', component: TradeFairComponent },
	{ path: 'thankyou', component: ThankyouComponent },
	{ path: ':slug', component: ProductDetailComponent },
	{ path: '', component: IndexComponent,  pathMatch: 'full' },

];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
	  scrollPositionRestoration: 'enabled',
	  relativeLinkResolution: 'legacy',
	  initialNavigation: 'enabledBlocking',
	  anchorScrolling: 'enabled'
   
   })],
	exports: [RouterModule]
   })
export class AppRoutingModule { }
