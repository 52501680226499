<!-- head title Start -->
<div class="float-100 banner-height">
    <owl-carousel *ngIf="availableBanners" [options]="{items: 1, dots: true, navigation: false, autoplay : true, loop : true,lazyLoad:false}" [carouselClasses]="['owl-theme', 'sliding']">
        <div class="item" *ngFor="let b of banners">
            <picture class="img-responsive">
                <source media="(max-width: 500px)" srcset="{{ env.BannerUrl + (b.BannerImage).replace('.','_small.') }}.webp" type="image/webp" >
                <source media="(max-width: 500px)" srcset="{{ env.BannerUrl + (b.BannerImage).replace('.','_small.') }}" type="image/jpg">

                <source media="(min-width: 501px)" srcset="{{ env.BannerUrl + b.BannerImage }}.webp" type="image/webp">
                <source media="(min-width: 501px)" srcset="{{ env.BannerUrl + b.BannerImage }}" type="image/jpg">
                <img width="1423" height="483" class="img-responsive" src="{{ env.BannerUrl + (b.BannerImage) }}" />
            </picture>
        </div>
    </owl-carousel>
</div>

<div class="div-head animated fadeInDown heading">
    <h1>Manufacturer &amp; Exporter of Conventional &amp; <br>Organic certified Oilseeds, Grains &amp; Spices</h1>
</div>

<!-- head title End -->
<!-- content of index Start -->
<div class="entry-content pt-45">
    <div class="container">
        <div class="row">
            <div class="col-lg-9 col-md-8 col-sm-6 col-xs-12">
                <p><strong>SHYAM INDUSTRIES</strong>&nbsp;is a leading &amp; reliable, professionally managed company – a&nbsp; wholesale supplier, manufacturer  &amp; star exporter of Oil Seeds, Grains &amp; Spices – both Conventional &amp; Organic  Certified, SINCE THE YEAR 1992. These products include Hulled Sesame  Seeds, Natural Sesame Seeds, Black Sesame Seeds, Roasted Sesame Seeds,  Toasted Sesame Seeds, Flax Seeds (Linseeds), Roasted Flax Seeds, Amaranth, Soybeans, Nigella (Kalonji) Seeds,  Dill Seeds, Ajwain (Carom) Seeds, Fenugreek Seeds, Golden Yellow Sesame Seeds, Brown Mustard Seeds, Yellow Mustard Seeds, Finger Millet, Pearl Millet, etc.</p>
                <p>The company as wholesale supplier and manufacturer oilseeds, Grains &amp; spices has modern hi-tech infrastructural  facilities with state-of-art machineries for getting the world best quality  products being manufactured under hygienic environment.</p>
                <p>The company as exporter of oilseeds, grains &amp; spices has been accredited for certification of&nbsp;<strong>BRC FOOD,</strong><strong> KOSHER and HALAL</strong>,  which positively endorse its fine processing and quality.</p>
                <p>With incomparable high quality products' consistent supplies, as oilseeds, grains &amp; spices exporters and manufacturers the company has earned good reputation  in the world and has built-up long-term customers base in Australia, USA,  Canada, UK, Europe, South America &amp; Asia pacific countries, etc. </p><a style="float: right;" href="/about-us/"><strong>Read More</strong></a>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <h2 class="slider-title">News &amp; Events</h2>
                <div class="set-fonts">
                <owl-carousel *ngIf="avilablenews" [options]="{items: 1, dots: true, nav: false, navigation: false, autoplay : true, loop : true,lazyLoad:true}" [carouselClasses]="['owl-theme', 'sliding']">
                    <div class="item" *ngFor="let n of News">
                        <picture class="img-responsive">
                            <source type="image/webp" srcset="{{ env.EventImages + n.EventImage }}.webp" class="img-responsive">
                            <img width="255" height="108" class="img-responsive" src="{{ env.EventImages + n.EventImage }}">
                        </picture>
                        <div class="text-center">
                         <h5 class="light-font">{{ n.EventTitle }}</h5>
                            <div class="text-muted">{{ n.Eventdate }}</div>
                            <div [innerHTML]="n.EventLocation">
                            </div>
                        </div>
                    </div>
                </owl-carousel>
               </div>
            </div>
        </div>
    </div>
    <div class="certificates">
        <div class="container">
            <div class="certi">
                <h2 class="content-title">Certificates</h2> <br>
                <ul *ngIf="availableCertificates">
                    <li *ngFor="let c of HomeCertificates">
                        <picture class="img-responsive">
                            <source type="image/webp" srcset="{{ env.CertificatesImage + c.CertificateLogo }}.webp" class="img-responsive">
                            <img alt="{{ c.CertificateTitle }}" width="75" height="81" class="img-responsive" src="{{ env.CertificatesImage + c.CertificateLogo }}">
                        </picture>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="div-sesame">
        <picture class="img-responsive">
            <source type="image/webp" srcset="../assets/img/img/sesame.webp" class="img-responsive">
            <img class="img-responsive" width="1423" height="200" src="../assets/img/img/sesame.jpg" class="w-100">
        </picture>
    </div>
    <div class="our-products">
        <div class="container">
            <h2 class="content-title">Our Products</h2>
            <owl-carousel *ngIf="availableProducts" [options]="customOptions" [carouselClasses]="['owl-theme', 'sliding']">
                <div class="item" *ngFor="let p of products">
                    <div class="item-box">
                        <div class="img-box">
                            <a routerLink="/{{ p.ProductSlug }}">
                                <picture class="img-responsive">
                                    <source type="image/webp" srcset="{{env.ProductImage + p.ProductThumbImage}}.webp" class="img-responsive">
                                    <img alt="{{ p.ImageAlt }}"  width="135" height="135" class="img-responsive" src="{{env.ProductImage + p.ProductThumbImage}}">
                                </picture>
                            </a>
                        </div>
                        <h4 class="item-title">{{ p.ProductName }}</h4>
                    </div>
                </div>

            </owl-carousel>
        </div>
    </div>
</div>
<!-- content of index End -->
