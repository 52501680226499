import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomService } from '../custom.service';
import { SeoService } from '../seo.service';
import { Router } from '@angular/router'
@Component({
    selector: 'app-career',
    templateUrl: './career.component.html',
    styleUrls: ['./career.component.css']
})
export class CareerComponent implements OnInit {
    carrerForm : any;
    submitted : any;
    FileData : File = null;
    uploaderrormsg : any;
    uploadError : boolean = false;
    showSuccess : boolean = false;
    showError : boolean = false;
    seoArray = [];
    constructor(
        public fb : FormBuilder,
        public customservice : CustomService,
        public seoService: SeoService,
        public router : Router
    ) {

    }

    ngOnInit() {

        // seo section
        this.customservice.getPageDetail(13).subscribe(
            (data : any)=>{
                this.seoArray.push(
                    {name: 'description', content:data.PageInfo.results[0].MetaDesc},
                    {name: 'keywords', content: data.PageInfo.results[0].Keywords}
                )
                this.seoService.setTitle(data.PageInfo.results[0].MetaTitle);
                this.seoService.addMetaTags(this.seoArray);
                this.seoService.createLinkForCanonicalURL();
            },(error:any)=>{
                console.log("Oops,there is some error,please try again.");
            }
        )
        // end

        this.carrerForm = this.fb.group({
            personname    : ['',Validators.required],
            email         : ['',[Validators.required,Validators.email]],
            applypost     : ['',Validators.required],
            contactnumber : ['',Validators.required],
            uploadresume  : [null,Validators.required],
            coveringtext  : ['',Validators.required],
        });

    }

    get f(){
        return this.carrerForm.controls;
    }

    onSubmit(){
        this.submitted = true;
        if(this.carrerForm.invalid){
            return false;
        }

        this.customservice.sendCareer(this.carrerForm.value).subscribe(
            (data : any)=>{
                if(data == 1){

                    this.showSuccess = true;
                    this.carrerForm.reset();
                    setTimeout(() => {
                        this.showSuccess = false;
                    }, 5000);

                    Object.keys(this.carrerForm.controls).forEach(key => {
                        this.carrerForm.get(key).setErrors(null);
                    });
                }
            },(error : any)=>{
                this.showError = true;
                this.carrerForm.reset();
                setTimeout(() => {
                    this.showSuccess = false;
                }, 5000);

                Object.keys(this.carrerForm.controls).forEach(key => {
                    this.carrerForm.get(key).setErrors(null);
                });
            }

        )
    }

    uploadFile(files : FileList){
        this.FileData =  files.item(0);
        this.customservice.uploadResume(this.FileData).subscribe(
            (data : any)=>{
                if(data.error == 'true'){
                    this.uploaderrormsg = data.message;
                    this.uploadError = true;
                }
                if(data.error == 'false'){
                    this.carrerForm.patchValue({uploadresume:data.filename});
                    //this.carrerForm.controls["uploadresume"].setValue(data.filename);

                    //this.carrerForm.uploadresume = data.filename;
                }


            },(error : any)=>{
                console.log("Oops there is some error.please try again.");
            }


        )

    }

    resetForm(){
        this.carrerForm.reset();
        Object.keys(this.carrerForm.controls).forEach(key => {
            this.carrerForm.get(key).setErrors(null);
        });

    }


}
