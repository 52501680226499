import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { environment } from '../environments/environment';
const url = environment.Api;
@Injectable({
    providedIn: 'root'
})
export class CustomService {

    constructor(
        public http : HttpClient
    ) {

    }

    getBanners(){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        return this.http.get(url + 'show_banners',{headers : headers});
    }

    getCategories(){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        return this.http.get(url + 'show_categorie',{headers : headers});
    }

    getProducts(type = ''){
        let headers = new HttpHeaders();
        headers = headers.append('Access-Control-Allow-Origin', '*')
        .append('Show-Summary', '1');
        return this.http.get(url + 'show_products',{headers : headers});
    }

    getProductDetail(Slug){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        return this.http.get(url + 'show_products?ProductSlug='+Slug,{headers : headers});
    }

    getCertificates(){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        return this.http.get(url + 'show_certificates',{headers : headers});
    }

    getPageDetail(PageId){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        return this.http.get(url + 'show_pages?PageId='+PageId,{headers : headers});
    }

    sendEnquiry(Enquiry){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        return this.http.post(url + 'send_enquiry',{Enquiry},{headers : headers});
    }

    sendCareer(Career){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        return this.http.post(url + 'sendcareer',{Career},{headers : headers});
    }

    getEvents(){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        return this.http.get(url + 'show_newsandevents',{headers : headers});
    }

    uploadResume(fileToUpload: File){
        let headers = new HttpHeaders();
        const formData: FormData = new FormData();
        formData.append('fileinfo', fileToUpload);
        return this.http.post<any>(url + 'uploadFile', formData, { headers: headers });
    }

    getProductsbyCategoryId(CategoryId){

        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        return this.http.get(url + 'showproductsbycategoryid?CategoryId='+CategoryId,{headers : headers});
    }


}
