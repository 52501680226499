<!-- <div class="overlay1"></div> -->

<!-- responsive Mobile menu -->
<!-- <div class="wrapper">
    <nav id="sidebar1">
        <div id="dismiss1" #dismiss1>
            <i class="glyphicon glyphicon-arrow-left"></i>
        </div>
        <ul class="list-unstyled components1 ul-width">
            <li>
                <a routerLink="/" (click)="closemenu()">Home</a>
                <a routerLink="/about-us" (click)="closemenu()">About Us</a>
                <div style="position: relative;">
                    <a routerLink="/products" data-toggle="collapse" aria-expanded="false" data-target="#pageSubmenu" (click)="closemenu()">Products</a>
                    <span data-toggle="collapse" aria-expanded="false" data-target="#pageSubmenu" class="drop-arrow"></span>
                </div>
                <ul class="collapse list-unstyled ul-width subdropdown" id="pageSubmenu" *ngIf="availableCategory">
                    <li *ngFor="let s of AllCategory">
                        <a routerLink="{{ '/' + s.CategorySlug }}" (click)="closemenu()">{{ s.CategoryTitle }}</a>
                    </li>
                </ul>
                <a routerLink="/organic-seeds" (click)="closemenu()">Organic</a>
                <a routerLink="/infrastructure" (click)="closemenu()">Infrastructure</a>
                <a routerLink="/private-labeling" (click)="closemenu()">Private Labeling</a>
                <a routerLink="/trade-fair" (click)="closemenu()">Trade Fair</a>
                <a routerLink="/enquiry" (click)="closemenu()">Enquiry</a>
                <a routerLink="/career" (click)="closemenu()">Career</a>
                <a routerLink="/contact-us" (click)="closemenu()">Contact Us</a>
            </li>
        </ul>
    </nav>
</div>
<button type="button" id="sidebarCollapse" class="btn nav-menu1" style="background-color: #000">
<i class="glyphicon glyphicon-align-justify" style="font-size: 1.5em;color: #FFF"></i>
<span></span>
</button> -->
<!-- responsive Mobile menu END -->

<button type="button" id="sidebarCollapse" class="btn nav-menu1 bg-black">
    <i class="glyphicon glyphicon-align-justify" *ngIf="mobileNav == false"  (click)="mobileNav = true"></i>
    <i class="glyphicon glyphicon-remove" *ngIf="mobileNav == true" (click)="mobileNav = false"></i>
</button>
<div class="mobile-nav" *ngIf="mobileNav">
    <ul id="primary-menu" class="nav-menu">
        <li>
            <a routerLink="/" (click)="mobileNav = false">Home</a>
        </li>
        <li>
            <a routerLink="/about-us" (click)="mobileNav = false">About Us</a>
        </li>
        <li>
            <a routerLink="/products">Products</a>
            <ul class="sub-menu" *ngIf="availableCategory">
                <li *ngFor="let s of AllCategory">
                    <a routerLink="{{ '/' + s.CategorySlug }}" (click)="mobileNav = false">{{ s.CategoryTitle }}</a>
                </li>
            </ul>
        </li>
        <li>
            <a routerLink="/organic-seeds" (click)="mobileNav = false">Organic</a>
        </li>
        <li>
            <a routerLink="/infrastructure" (click)="mobileNav = false">Infrastructure</a>
        </li>
        <li>
            <a routerLink="/certificates" (click)="mobileNav = false">Certificates</a>
        </li>
        <li>
            <a routerLink="/private-labeling" (click)="mobileNav = false">Private  Labeling</a>
        </li>
        <li>
            <a routerLink="/trade-fair" (click)="mobileNav = false">Trade Fair</a>
        </li>
        <li>
            <a routerLink="/enquiry" (click)="mobileNav = false">Enquiry</a>
        </li>
        <li>
            <a routerLink="/career" (click)="mobileNav = false">Career</a>
        </li>
        <li>
            <a routerLink="/contact-us" (click)="mobileNav = false">Contact Us</a>
        </li>
    </ul>
</div>





<div class="topbar-widget">
    <div id="black-studio-tinymce-4" class="widget-1 widget-first widget-odd topbar widget widget_black_studio_tinymce">
        <div class="textwidget">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pull-left top-since">
                        <span>SINCE 1992</span>
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12 contact-link">
                        <ul class="pull-right">
                            <li><a href="tel:+917922822365" onclick="gtag('event', 'Click', { 'send_to': 'UA-1547997-1', 'event_category': 'Call', 'event_label': '+917922822365' });">+91-79-22822365</a></li>
                            <li><a href="skype:sanjay.vachhani" onclick="gtag('event', 'Click', { 'send_to': 'UA-1547997-1', 'event_category': 'SkypeCall', 'event_label': 'skype:sanjay.vachhani' });">sanjay.vachhani</a></li>
                            <li><a href="mailto:info@shyam.in" onclick="gtag('event', 'Click', { 'send_to': 'UA-1547997-1', 'event_category': 'Email', 'event_label': 'info@shyam.in' });">info@shyam.in</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container visible-xs">
    <div class="row">

        <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12 text-center">
            <a class="logo" routerLink="/">
                <img src="assets/img/img/shyam_logo.png" title="Manufacturer & Exporter of Organic Oilseeds, Grains, Spices" width="240" height="143">
            </a>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
            <img src="assets/img/img/best-in-taste.jpg" class="img-responsive"  width="209" height="118">
        </div>
        <div class="col-lg-5 col-md-5 col-sm-4 col-xs-6">
            <div class="pull-right div-export">
                <img style="margin-top:10px;" class="img-responsive" src="assets/img/img/star-export-house.jpg" width="256" height="51">
            </div>
        </div>
    </div>
</div>

<div class="container hidden-xs">
    <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6 col-xs-fullwidth">
            <img src="assets/img/img/best-in-taste.jpg" width="209" height="118">
        </div>
        <div class="col-lg-3 col-md-3 col-sm-4 col-xs-6 col-xs-fullwidth">
            <a class="logo" routerLink="/">
                <img src="assets/img/img/shyam_logo.png" title="Manufacturer & Exporter of Organic Oilseeds, Grains, Spices" width="240" height="143">
            </a>
        </div>
        <div class="col-lg-5 col-md-5 col-sm-4 col-xs-12 col-xs-fullwidth">
            <div class="pull-right div-export">

                <img style="margin-top:10px;" src="assets/img/img/star-export-house.jpg" width="256" height="51">
            </div>
        </div>
    </div>
</div>
<!-- Menu set here -->
<div id="navbar" class="navbar">
    <nav id="site-navigation" class="navigation main-navigation" role="navigation">
        <button class="menu-toggle">
            Menu
        </button>
        <a class="screen-reader-text skip-link" href="#content" title="Skip to content">
            Skip to content
        </a>
        <div class="menu-header-menu-container">
            <ul id="primary-menu" class="nav-menu">
                <li>
                    <a routerLink="/">Home</a>
                </li>
                <li>
                    <a routerLink="/about-us">About Us</a>
                </li>
                <li>
                    <a routerLink="/products">Products</a>
                    <ul class="sub-menu" *ngIf="availableCategory">
                        <li *ngFor="let s of AllCategory">
                            <a routerLink="{{ '/' + s.CategorySlug }}">{{ s.CategoryTitle }}</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a routerLink="/organic-seeds">Organic</a>
                </li>
                <li>
                    <a routerLink="/infrastructure">Infrastructure</a>
                </li>
                <li>
                    <a routerLink="/certificates">Certificates</a>
                </li>
                <li>
                    <a routerLink="/private-labeling">Private  Labeling</a>
                </li>
                <li>
                    <a routerLink="/trade-fair">Trade Fair</a>
                </li>
                <li>
                    <a routerLink="/enquiry">Enquiry</a>
                </li>
                <li>
                    <a routerLink="/career">Career</a>
                </li>
                <li>
                    <a routerLink="/contact-us">Contact Us</a>
                </li>
            </ul>
        </div>
    </nav>
</div>

<!-- Quick Contact Start -->


<!--

<div class="quick-cont-main">
  <div class="quick-image">
    <img src="assets/img/img/quick.png">
  </div>
  <div class="quick-form" style="display: block;">
      <div role="form" class="wpcf7" id="wpcf7-f925-o1" lang="en-US" dir="ltr">
<div class="screen-reader-response"></div>
<form action="/certificates/#wpcf7-f925-o1" method="post" class="wpcf7-form" novalidate="novalidate">
<div style="display: none;">
<input type="hidden" name="_wpcf7" value="925">
<input type="hidden" name="_wpcf7_version" value="4.7">
<input type="hidden" name="_wpcf7_locale" value="en_US">
<input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f925-o1">
<input type="hidden" name="_wpnonce" value="b767bc0c87">
</div>
<div class="quick-frm layout-set">
<div class="frm-right"><span class="wpcf7-form-control-wrap fname"><input type="text" name="fname" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false" placeholder="Name*"></span></div>
<div class="frm-right"><span class="wpcf7-form-control-wrap email"><input type="email" name="email" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email" aria-required="true" aria-invalid="false" placeholder="E-mail*"></span></div>
<div class="frm-right"><span class="wpcf7-form-control-wrap telephone"><input type="tel" name="telephone" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel" aria-required="true" aria-invalid="false" placeholder="Phone Number*"></span></div>
<div class="frm-right"><span class="wpcf7-form-control-wrap message"><textarea name="message" cols="40" rows="3" class="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required" aria-required="true" aria-invalid="false" placeholder="Message*"></textarea></span></div>
<div class="frm-right">
<div class="wpcf7-form-control-wrap"><div data-sitekey="6Ld-Jx8UAAAAAAcRy9k36OeSspvd1DXQe0GbmUHy" class="wpcf7-form-control g-recaptcha wpcf7-recaptcha"><div style="width: 304px; height: 78px;"><div><iframe src="https://www.google.com/recaptcha/api2/anchor?ar=1&amp;k=6Ld-Jx8UAAAAAAcRy9k36OeSspvd1DXQe0GbmUHy&amp;co=aHR0cHM6Ly93d3cuc2h5YW1pbmQuY29tOjQ0Mw..&amp;hl=en&amp;v=Zy-zVXWdnDW6AUZkKlojAKGe&amp;size=normal&amp;cb=k2pgbpmiqfqs" width="304" height="78" role="presentation" name="a-vwbh275e3lv5" frameborder="0" scrolling="no" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox"></iframe></div><textarea id="g-recaptcha-response" name="g-recaptcha-response" class="g-recaptcha-response" style="width: 250px; height: 40px; border: 1px solid rgb(193, 193, 193); margin: 10px 25px; padding: 0px; resize: none; display: none;"></textarea></div></div>
<noscript>
    <div style="width: 302px; height: 422px;">
        <div style="width: 302px; height: 422px; position: relative;">
            <div style="width: 302px; height: 422px; position: absolute;">
                <iframe src="https://www.google.com/recaptcha/api/fallback?k=6Ld-Jx8UAAAAAAcRy9k36OeSspvd1DXQe0GbmUHy" frameborder="0" scrolling="no" style="width: 302px; height:422px; border-style: none;">
                </iframe>
            </div>
            <div style="width: 300px; height: 60px; border-style: none; bottom: 12px; left: 25px; margin: 0px; padding: 0px; right: 25px; background: #f9f9f9; border: 1px solid #c1c1c1; border-radius: 3px;">
                <textarea id="g-recaptcha-response" name="g-recaptcha-response" class="g-recaptcha-response" style="width: 250px; height: 40px; border: 1px solid #c1c1c1; margin: 10px 25px; padding: 0px; resize: none;">
                </textarea>
            </div>
        </div>
    </div>
</noscript>
</div>
</div>
<div class="frm-right"><input type="submit" value="Submit" class="wpcf7-form-control wpcf7-submit"><span class="ajax-loader"></span></div>
</div>
<div class="wpcf7-response-output wpcf7-display-none"></div></form></div>  </div>
</div>


-->


<!-- Quick Contact End -->
