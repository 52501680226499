import { Component, OnInit } from '@angular/core';
import { OwlModule } from 'ngx-owl-carousel';
import { CustomService } from '../custom.service';
import { environment } from '../../environments/environment';
import { SeoService } from '../seo.service';

@Component({
    selector: 'app-index',
    templateUrl: './index.component.html',
    styleUrls: ['./index.component.css']
})

export class IndexComponent implements OnInit {
    env : any;
    availableBanners  : boolean = false;
    availableProducts : boolean = false;
    availableCertificates : boolean = false;
    News : any = [];
    avilablenews : boolean = false;
    banners : any;
    products : any;
    HomeCertificates : any = [];
    seoArray = [];

    customOptions: any = {
        loop: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        autoWidth: true,
        autoplay: true,
        autoplayTimeout: 2000,
        dots: false,
        navSpeed: 700,
        navText: ['', ''],
        margin: 10,
        responsive: {
            0: {
                items: 1
            },
            400: {
                items: 2
            },
            740: {
                items: 3
            },
            940: {
                items: 3
            }
        },
        nav: true,
        lazyLoad:true
    }

    constructor(
        public customservice : CustomService,
        public seoService: SeoService
    ) {
        this.env = environment;
    }

    ngOnInit() {

        // seo section
        this.customservice.getPageDetail(9).subscribe(
            (data : any)=>{
                this.seoArray.push(
                    {name: 'description', content:data.PageInfo.results[0].MetaDesc},
                    {name: 'keywords', content: data.PageInfo.results[0].Keywords}
                )
                this.seoService.setTitle(data.PageInfo.results[0].MetaTitle);
                this.seoService.addMetaTags(this.seoArray);
                this.seoService.createLinkForCanonicalURL();
            },(error:any)=>{
                console.log("Oops,there is some error,please try again.");
            }
        )
        // end

        this.customservice.getBanners().subscribe(
            (data : any)=>{
                if(data.banners.results.length > 0){
                    this.banners = data.banners.results;
                    this.availableBanners = true;
                }
            },(error :any)=>{
                console.log("Oops there is some error.");
            }
        )

        // Fetch products
        this.customservice.getProducts().subscribe(
            (data :any)=>{
                if(data.products.results.length > 0){
                    this.products = data.products.results;
                    this.availableProducts = true;
                }

            },(error : any)=>{
                console.log("Oops there is some error.");
            }
        )
        // Get news And Events
        this.customservice.getEvents().subscribe(
            (data : any)=>{

                for (let i = 0; i < data.all_newsandevents.results.length; i++) {
                    if(data.all_newsandevents.results[i].EventType == '1'){
                        this.News.push(data.all_newsandevents.results[i]);
                        this.avilablenews = true;
                    }
                }
            },(error)=>{
                console.log("Oops there is some errors")
            }
        )

        // Get Certificates
        this.customservice.getCertificates().subscribe(
            (data :any)=>{
                if(data.all_certificates.results.length > 0){

                    for(let c=0;c < data.all_certificates.results.length;c++){
                        if(data.all_certificates.results[c].DisplayOnHome == '1'){
                            this.HomeCertificates.push(data.all_certificates.results[c]);
                        }
                    }
                    if(this.HomeCertificates.length > 0){
                        this.availableCertificates = true;
                    }
                    // HomeCertificates
                }
            },(error : any)=>{
                console.log("Oops there is some error.");
            }
        )
    }
}
