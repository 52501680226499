<div id="div_banner" class="animated fadeIn" *ngIf="availablePageDetail">
    <picture class="img-responsive">
        <source type="image/webp" srcset="{{env.PageBannerImage + PageDetail.PageBanner}}.webp" class="img-responsive">
            <img width="1423" height="240" class="img-responsive w-100" src="{{env.PageBannerImage + PageDetail.PageBanner}}">
        </picture>
    </div>

    <ng-container *ngIf="availablePageDetail">
        <div [innerHTML]="PageDesc" class="setmargin"></div>
    </ng-container>
