<!-- Categorie wise products -->
<div *ngIf="availableBanner && availableCategoryProducts">
    <img width="1423" height="240" src="{{ env.CategorieBanner +Banner }}" alt="" class="img-fluid w-100">
</div>

<div class="container">
    <div class="row padding-30 catproduct" *ngIf="availableCategoryProducts">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 catproductdiv" *ngFor="let p of products;">
            <a routerLink="{{ '/'+ p.ProductSlug }}">
                <h4 class="gal_title">{{ p.ProductName }}</h4>
                <div class="item-box1">
                    <!-- <div class="img-box" [ngStyle]="{'background-size':'100%','min-height':'125px','background-image': 'url(' + env.ProductImage + p.ProductImage +')'}"></div> -->
                    <picture class="img-responsive h-120 ov-hidden">
                        <source type="image/webp" srcset="{{env.ProductImage + p.ProductImage}}.webp" class="img-responsive">
                        <img alt="{{p.ImageAlt}}" width="369" height="125" class="img-responsive" src="{{env.ProductImage + p.ProductImage}}">
                    </picture>
                    <div class="entry-content cat-excerpt">
                        <div [innerHTML]="p.smallDesc"></div>
                        <div class="more-details">
                            <a routerLink="{{ p.ProductSlug }}" rel="bookmark">More Details</a>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </div>
</div>
<!-- End -->


<!-- All products -->
<div>
    <div *ngIf="availableBanner && availableProducts">
        <img width="1423" height="240" src="{{ env.PageBannerImage +Banner}}" alt="" class="img-fluid w-100">
    </div>
    <div class="container">
        <div class="row" *ngIf="availableProducts">
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" *ngFor="let p of products;">
                <a routerLink="{{ '/'+ p.ProductSlug }}">
                    <div class="item-box1">
                        <picture class="img-responsive h-120 ov-hidden">
                            <source type="image/webp" srcset="{{env.ProductImage + p.ProductImage}}.webp" class="img-responsive">
                            <img width="369" height="125" class="img-responsive" src="{{env.ProductImage + p.ProductImage}}">
                        </picture>
                        <h4 class="gal_title">{{ p.ProductName }}</h4>
                    </div>
                </a>
            </div>
        </div> <br><br>
    </div>
</div>
<!-- End -->
