import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomService } from '../custom.service';
import { SeoService } from '../seo.service';
import { Router } from '@angular/router'
@Component({
    selector: 'app-enquiry',
    templateUrl: './enquiry.component.html',
    styleUrls: ['./enquiry.component.css']
})
export class EnquiryComponent implements OnInit {

    enquirForm : any;
    submitted : boolean = false;
    showSuccess : boolean = false;
    showError : boolean = false;
    seoArray = [];

    constructor(
        public  fb : FormBuilder,
        public customservice : CustomService,
        public seoService: SeoService,
        public router : Router
    ) { }

    ngOnInit() {

        // seo section
        this.customservice.getPageDetail(12).subscribe(
            (data : any)=>{
                this.seoArray.push(
                    {name: 'description', content:data.PageInfo.results[0].MetaDesc},
                    {name: 'keywords', content: data.PageInfo.results[0].Keywords}
                )
                this.seoService.setTitle(data.PageInfo.results[0].MetaTitle);
                this.seoService.addMetaTags(this.seoArray);
                this.seoService.createLinkForCanonicalURL();
            },(error:any)=>{
                console.log("Oops,there is some error,please try again.");
            }
        )
        // end

        this.enquirForm = this.fb.group({
            companyname   : ['', Validators.required],
            contactperson : ['', Validators.required],
            phone         : ['', Validators.required],
            email         : ['', [Validators.required,Validators.email]],
            country       : ['', Validators.required],
            message       : ['', Validators.required],
        })
    }

    get f(){
        return this.enquirForm.controls;
    }
    onSubmit(){


        this.submitted = true;
        if(this.enquirForm.invalid){
            return false;
        }

        this.customservice.sendEnquiry(this.enquirForm.value).subscribe(
            (data : any)=>{
                if(data == 1){
                    this.router.navigate(['/thankyou']);
                    //this.showSuccess = true;
                }
            },(error : any)=>{
                this.showError = true;
            }
        )

        this.enquirForm.reset();
        setTimeout(() => {
            this.showSuccess = false;
        }, 5000);

        Object.keys(this.enquirForm.controls).forEach(key => {
            this.enquirForm.get(key).setErrors(null);
        });

    }

    resetForm(){
        this.enquirForm.reset();
        Object.keys(this.enquirForm.controls).forEach(key => {
            this.enquirForm.get(key).setErrors(null);
        });

    }
}
