<div class="container thankyou">

    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <header class="about-entry-header">
                <h1 class="about-entry-title">Thank you</h1>
            </header>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
            <h2>We will contact you shortly.</h2>
        </div>
    </div>

</div>
