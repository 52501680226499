<div class="container">
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <header class="about-entry-header">
                <h1 class="about-entry-title">Certificates</h1>
            </header>
        </div>
    </div>

    <div>
        <div class="row div-certi" *ngIf="availableCertificates">
            <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12" *ngFor="let c of AllCertificates">
                <picture class="img-responsive h-125 ov-hidden" *ngIf="c.CertificateTitle != 'TEMP'">
                    <source type="image/webp" srcset="{{env.CertificatesImage + c.CertificateImage}}.webp" class="img-responsive">
                    <img class="img-responsive" src="{{env.CertificatesImage + c.CertificateImage}}">
                </picture>
                <span *ngIf="c.CertificateTitle != 'TEMP'">{{ c.CertificateTitle }}</span>
            </div>
        </div>

        <div class="row" *ngIf="!availableCertificates">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                <h5>No certificates are available.</h5>
            </div>
        </div>
    </div>



</div>
