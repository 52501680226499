import { Component, OnInit } from '@angular/core';
import { CustomService } from '../custom.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
import { SeoService } from '../seo.service';
@Component({
    selector: 'app-product-detail',
    templateUrl: './product-detail.component.html',
    styleUrls: ['./product-detail.component.css']
})
export class ProductDetailComponent implements OnInit {
    availableproduct : boolean = false;
    productdetial : any;
    slug : any;
    env : any;

    CategoryId : any;
    getProducts : any;
    products : any;
    availableProducts : boolean = false;

    nextTitle : any;
    previousTitle : any;
    nextSlug : any;
    previousSlug : any;
    seoArray = [];

    constructor(
        public customservice : CustomService,
        public router : ActivatedRoute,
        public seoService: SeoService
    ) {

        this.env  = environment;
        this.router.params.subscribe(
            (data : any)=>{
                console.log(data);
                this.slug = data['slug'];
                this.customservice.getProducts().subscribe(
                    (data : any)=>{
                        if(data.products.results.length > 0){
                            this.products = data.products.results;


                            this.availableProducts = true;
                        }
                    },(error)=>{
                        console.log(error)
                    }
                )
                this.fetchProductDetail(this.slug);
            }
        )
    }

    ngOnInit() {


    }

    fetchProductDetail(slug){

        this.customservice.getProductDetail(slug).subscribe(
            (data : any)=>{


                if(data.products.results.length > 0){
                    this.productdetial = data.products.results[0];

                    this.seoArray.push(
                        {name: 'description', content:this.productdetial.MetaDesc},
                        {name: 'keywords', content: this.productdetial.Keywords}
                    )
                    this.seoService.setTitle(this.productdetial.MetaTitle);
                    this.seoService.addMetaTags(this.seoArray);
                    this.seoService.createLinkForCanonicalURL();
                    this.availableproduct = true;
                    this.CategoryId = this.productdetial.CategoryId;
                    //this.getProductsCategorywise(this.CategoryId);
                }
                // next product chceck
                if(data.next_product.length > 0){
                    this.nextTitle = data.next_product[0].ProductName;
                    this.nextSlug =  data.next_product[0].ProductSlug;
                }

                if(data.previous_product.length > 0){
                    this.previousSlug  = data.previous_product[0].ProductSlug;
                    this.previousTitle = data.previous_product[0].ProductName;
                }

            },(error :any)=>{
                console.log("Oops there is some error.");
            }
        )
    }

    // getProductsCategorywise(CategoryId){
    //     this.customservice.getProductsbyCategoryId(CategoryId).subscribe(
    //         (data :any)=>{
    //             console.log(data);
    //         },(error :any)=>{
    //             console.log("Oops there is some error.");
    //         }
    //     )
    // }

}
