import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { IndexComponent } from './index/index.component';
import { OwlModule } from 'ngx-owl-carousel';
import { CustomService } from './custom.service';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { PagesComponent } from './pages/pages.component';
import { CertificatesComponent } from './certificates/certificates.component';
import { EnquiryComponent } from './enquiry/enquiry.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CareerComponent } from './career/career.component';
import { TradeFairComponent } from './trade-fair/trade-fair.component';
import { ProductsComponent } from './products/products.component';
import { SeoService } from './seo.service';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { RouterModule } from '@angular/router';
import { TransferHttpCacheModule } from '@nguniversal/common';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        IndexComponent,
        ProductDetailComponent,
        PagesComponent,
        CertificatesComponent,
        EnquiryComponent,
        CareerComponent,
        TradeFairComponent,
        ProductsComponent,
        ThankyouComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        TransferHttpCacheModule,
        AppRoutingModule,
        BrowserModule,
        OwlModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ReactiveFormsModule,
        RouterModule
    ],
    providers: [
        CustomService,
        SeoService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
