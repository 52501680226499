<footer class="site-footer" id="colophon" role="contentinfo">
    <div class="sidebar-container" id="secondary" role="complementary">
        <div class="widget-area">
            <aside class="widget-1 widget-first widget-odd widget-map widget widget_black_studio_tinymce" id="black-studio-tinymce-5">
                <div class="textwidget">
                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                        <img height="262" class="img-responsive" src="assets/img/img/map1.png" width="650" />
                    </div>
                </div>
            </aside>
            <aside class="widget-2 widget-last widget-even widget-contact widget widget_black_studio_tinymce" id="black-studio-tinymce-6">
                <div class="textwidget">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <div class="contact-footer">
                            <div class="address">
                                <p>
                                    <strong>
                                        SHYAM INDUSTRIES
                                    </strong>
                                    <br/>
                                    402/403, Phase 4,
                                    <br/>
                                    GIDC, Naroda,
                                    <br/>
                                    Ahmedabad - 382 330
                                    <br/>
                                    Gujarat (India)
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                </p>
                            </div>
                            <div class="contact-no foot-link">
                                <a href="tel:+91-79-22822365" onclick="gtag('event', 'Click', { 'send_to': 'UA-1547997-1', 'event_category': 'Call', 'event_label': '+91-79-22822365' });">
                                    +91-79-22822365</a>,
                                    <span class="sec-num">
                                        <a href="tel:+91-79-22801298" onclick="gtag('event', 'Click', { 'send_to': 'UA-1547997-1', 'event_category': 'Call', 'event_label': '+91-79-22801298' });">
                                            +91-79-22801298
                                        </a>
                                    </span>
                                </div>
                                <div class="fax-no foot-link">
                                    <a href="fax:+91-79-22821433">
                                        +91-79-22821433
                                    </a>
                                </div>
                                <div class="div-footer-link">
                                    <div class="mail-id foot-link col-lg-6 col-xs-12">
                                        <a href="mailto:info@shyam.in" onclick="gtag('event', 'Click', { 'send_to': 'UA-1547997-1', 'event_category': 'Email', 'event_label': 'info@shyam.in' });">
                                            info@shyam.in
                                        </a>
                                    </div>
                                    <div class="skype-id foot-link col-lg-6 col-xs-12">
                                        <a class="skype-link" href="skype:sanjay.vachhani" onclick="gtag('event', 'Click', { 'send_to': 'UA-1547997-1', 'event_category': 'SkypeCall', 'event_label': 'skype:sanjay.vachhani' });">
                                            sanjay.vachhani
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </aside>
            </div>
        </div>
        <div class="site-info">
            <p>Copyright © 2019 SHYAM INDUSTRIES, All rights reserved.</p>
        </div>
    </footer>
    <!-- quick inquiry -->
    <div class="inquiry-cont-main">
        <div class="inquiry-image" *ngIf="!showInquiryform">
            <span class="enquiry-heading" (click)="showInquiryform = true">Quick Contact</span>
        </div>
    </div>
    <div class="inquiry-form" *ngIf="showInquiryform">
        <form class="" [formGroup]="sideform" (ngSubmit)="onSubmit()"  method="post">
            <div class="contact-frm">
                <div class="alert alert-success" *ngIf="showSuccess">
                    <strong>Thank you!</strong> we will contact you shortly.
                </div>

                <div class="alert alert-danger" *ngIf="showError">
                    <strong>Error!</strong> Oops,some error.please try again.
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                    <div class="enq-heading ">Enquiry</div>
                    <div class="frm-left">Company Name: </div>
                    <div class="frm-right">
                        <span class="wpcf7-form-control-wrap company-name">
                            <input type="text" formControlName="companyname"  value="" size="40" required [ngClass]="(submitted && f.companyname.errors) ? 'req-textbox' : ''">
                        </span>

                    </div>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                    <div class="frm-left">Contact Person: </div>
                    <div class="frm-right">
                        <span class="wpcf7-form-control-wrap contact-person">
                            <input type="text" formControlName="contactperson" required [ngClass]="(submitted && f.contactperson.errors) ? 'req-textbox' : '' ">
                        </span>

                    </div>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                    <div class="frm-left">Phone: </div>
                    <div class="frm-right">
                        <span class="wpcf7-form-control-wrap phone">
                            <input type="text" formControlName="phone" [ngClass]="(submitted && f.phone.errors) ? 'req-textbox' : '' ">
                        </span>

                    </div>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                    <div class="frm-left">E-mail: </div>
                    <div class="frm-right">
                        <span class="wpcf7-form-control-wrap email-352">
                            <input type="email" formControlName="email" [ngClass]="(submitted && f.email.errors) ? 'req-textbox' : '' ">
                        </span>

                    </div>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                    <div class="frm-left">Country: </div>
                    <div class="frm-right"><span class="wpcf7-form-control-wrap coutry">
                        <select formControlName="country" [ngClass]="(submitted && f.country.errors) ? 'req-textbox' : ''">
                            <option value="">---</option>
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="Albania">Albania</option>
                            <option value="Algeria">Algeria</option>
                            <option value="American Samoa">American Samoa</option>
                            <option value="Andorra">Andorra</option>
                            <option value="Angola">Angola</option>
                            <option value="Anguilla">Anguilla</option>
                            <option value="Antarctica">Antarctica</option>
                            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                            <option value="Argentina">Argentina</option>
                            <option value="Armenia">Armenia</option>
                            <option value="Arctic Ocean">Arctic Ocean</option>
                            <option value="Aruba">Aruba</option>
                            <option value="Ashmore and Cartier Islands">Ashmore and Cartier Islands</option>
                            <option value="Atlantic Ocean">Atlantic Ocean</option>
                            <option value="Australia">Australia</option>
                            <option value="Austria">Austria</option>
                            <option value="Azerbaijan">Azerbaijan</option>
                            <option value="Bahamas">Bahamas</option>
                            <option value="Bahrain">Bahrain</option>
                            <option value="Baker Island">Baker Island</option>
                            <option value="Bangladesh">Bangladesh</option>
                            <option value="Barbados">Barbados</option>
                            <option value="Bassas da India">Bassas da India</option>
                            <option value="Belarus">Belarus</option>
                            <option value="Belgium">Belgium</option>
                            <option value="Belize">Belize</option>
                            <option value="Benin">Benin</option>
                            <option value="Bermuda">Bermuda</option>
                            <option value="Bhutan">Bhutan</option>
                            <option value="Bolivia">Bolivia</option>
                            <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                            <option value="Botswana">Botswana</option>
                            <option value="Bouvet Island">Bouvet Island</option>
                            <option value="Brazil">Brazil</option>
                            <option value="British Virgin Islands">British Virgin Islands</option>
                            <option value="Brunei">Brunei</option>
                            <option value="Bulgaria">Bulgaria</option>
                            <option value="Burkina Faso">Burkina Faso</option>
                            <option value="Burundi">Burundi</option>
                            <option value="Cambodia">Cambodia</option>
                            <option value="Cameroon">Cameroon</option>
                            <option value="Canada">Canada</option>
                            <option value="Cape Verde">Cape Verde</option>
                            <option value="Cayman Islands">Cayman Islands</option>
                            <option value="Central African Republic">Central African Republic</option>
                            <option value="Chad">Chad</option>
                            <option value="Chile">Chile</option>
                            <option value="China">China</option>
                            <option value="Christmas Island">Christmas Island</option>
                            <option value="Clipperton Island">Clipperton Island</option>
                            <option value="Cocos Islands">Cocos Islands</option>
                            <option value="Colombia">Colombia</option>
                            <option value="Comoros">Comoros</option>
                            <option value="Cook Islands">Cook Islands</option>
                            <option value="Coral Sea Islands">Coral Sea Islands</option>
                            <option value="Costa Rica">Costa Rica</option>
                            <option value="Cote d'Ivoire">Cote d'Ivoire</option>
                            <option value="Croatia">Croatia</option>
                            <option value="Cuba">Cuba</option>
                            <option value="Cyprus">Cyprus</option>
                            <option value="Czech Republic">Czech Republic</option>
                            <option value="Denmark">Denmark</option>
                            <option value="Democratic Republic of the Congo">Democratic Republic of the Congo</option>
                            <option value="Djibouti">Djibouti</option>
                            <option value="Dominica">Dominica</option>
                            <option value="Dominican Republic">Dominican Republic</option>
                            <option value="East Timor">East Timor</option>
                            <option value="Ecuador">Ecuador</option>
                            <option value="Egypt">Egypt</option>
                            <option value="El Salvador">El Salvador</option>
                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                            <option value="Eritrea">Eritrea</option>
                            <option value="Estonia">Estonia</option>
                            <option value="Ethiopia">Ethiopia</option>
                            <option value="Europa Island">Europa Island</option>
                            <option value="Falkland Islands (Islas Malvinas)">Falkland Islands (Islas Malvinas)</option>
                            <option value="Faroe Islands">Faroe Islands</option>
                            <option value="Fiji">Fiji</option>
                            <option value="Finland">Finland</option>
                            <option value="France">France</option>
                            <option value="French Guiana">French Guiana</option>
                            <option value="French Polynesia">French Polynesia</option>
                            <option value="French Southern and Antarctic Lands">French Southern and Antarctic Lands</option>
                            <option value="Gabon">Gabon</option>
                            <option value="Gambia">Gambia</option>
                            <option value="Gaza Strip">Gaza Strip</option>
                            <option value="Georgia">Georgia</option>
                            <option value="Germany">Germany</option>
                            <option value="Ghana">Ghana</option>
                            <option value="Gibraltar">Gibraltar</option>
                            <option value="Glorioso Islands">Glorioso Islands</option>
                            <option value="Greece">Greece</option>
                            <option value="Greenland">Greenland</option>
                            <option value="Grenada">Grenada</option>
                            <option value="Guadeloupe">Guadeloupe</option>
                            <option value="Guam">Guam</option>
                            <option value="Guatemala">Guatemala</option>
                            <option value="Guernsey">Guernsey</option>
                            <option value="Guinea">Guinea</option>
                            <option value="Guinea-Bissau">Guinea-Bissau</option>
                            <option value="Guyana">Guyana</option>
                            <option value="Haiti">Haiti</option>
                            <option value="Heard Island and McDonald Islands">Heard Island and McDonald Islands</option>
                            <option value="Honduras">Honduras</option>
                            <option value="Hong Kong">Hong Kong</option>
                            <option value="Howland Island">Howland Island</option>
                            <option value="Hungary">Hungary</option>
                            <option value="Iceland">Iceland</option>
                            <option value="India">India</option>
                            <option value="Indian Ocean">Indian Ocean</option>
                            <option value="Indonesia">Indonesia</option>
                            <option value="Iran">Iran</option>
                            <option value="Iraq">Iraq</option>
                            <option value="Ireland">Ireland</option>
                            <option value="Isle of Man">Isle of Man</option>
                            <option value="Israel">Israel</option>
                            <option value="Italy">Italy</option>
                            <option value="Jamaica">Jamaica</option>
                            <option value="Jan Mayen">Jan Mayen</option>
                            <option value="Japan">Japan</option>
                            <option value="Jarvis Island">Jarvis Island</option>
                            <option value="Jersey">Jersey</option>
                            <option value="Johnston Atoll">Johnston Atoll</option>
                            <option value="Jordan">Jordan</option>
                            <option value="Juan de Nova Island">Juan de Nova Island</option>
                            <option value="Kazakhstan">Kazakhstan</option>
                            <option value="Kenya">Kenya</option>
                            <option value="Kingman Reef">Kingman Reef</option>
                            <option value="Kiribati">Kiribati</option>
                            <option value="Kerguelen Archipelago">Kerguelen Archipelago</option>
                            <option value="Kosovo">Kosovo</option>
                            <option value="Kuwait">Kuwait</option>
                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                            <option value="Laos">Laos</option>
                            <option value="Latvia">Latvia</option>
                            <option value="Lebanon">Lebanon</option>
                            <option value="Lesotho">Lesotho</option>
                            <option value="Liberia">Liberia</option>
                            <option value="Libya">Libya</option>
                            <option value="Liechtenstein">Liechtenstein</option>
                            <option value="Lithuania">Lithuania</option>
                            <option value="Luxembourg">Luxembourg</option>
                            <option value="Macau">Macau</option>
                            <option value="Macedonia">Macedonia</option>
                            <option value="Madagascar">Madagascar</option>
                            <option value="Malawi">Malawi</option>
                            <option value="Malaysia">Malaysia</option>
                            <option value="Maldives">Maldives</option>
                            <option value="Mali">Mali</option>
                            <option value="Malta">Malta</option>
                            <option value="Marshall Islands">Marshall Islands</option>
                            <option value="Martinique">Martinique</option>
                            <option value="Mauritania">Mauritania</option>
                            <option value="Mauritius">Mauritius</option>
                            <option value="Mayotte">Mayotte</option>
                            <option value="Mexico">Mexico</option>
                            <option value="Micronesia">Micronesia</option>
                            <option value="Midway Islands">Midway Islands</option>
                            <option value="Moldova">Moldova</option>
                            <option value="Monaco">Monaco</option>
                            <option value="Mongolia">Mongolia</option>
                            <option value="Montenegro">Montenegro</option>
                            <option value="Montserrat">Montserrat</option>
                            <option value="Morocco">Morocco</option>
                            <option value="Mozambique">Mozambique</option>
                            <option value="Myanmar">Myanmar</option>
                            <option value="Namibia">Namibia</option>
                            <option value="Nauru">Nauru</option>
                            <option value="Navassa Island">Navassa Island</option>
                            <option value="Nepal">Nepal</option>
                            <option value="Netherlands">Netherlands</option>
                            <option value="Netherlands Antilles">Netherlands Antilles</option>
                            <option value="New Caledonia">New Caledonia</option>
                            <option value="New Zealand">New Zealand</option>
                            <option value="Nicaragua">Nicaragua</option>
                            <option value="Niger">Niger</option>
                            <option value="Nigeria">Nigeria</option>
                            <option value="Niue">Niue</option>
                            <option value="Norfolk Island">Norfolk Island</option>
                            <option value="North Korea">North Korea</option>
                            <option value="North Sea">North Sea</option>
                            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                            <option value="Norway">Norway</option>
                            <option value="Oman">Oman</option>
                            <option value="Pacific Ocean">Pacific Ocean</option>
                            <option value="Pakistan">Pakistan</option>
                            <option value="Palau">Palau</option>
                            <option value="Palmyra Atoll">Palmyra Atoll</option>
                            <option value="Panama">Panama</option>
                            <option value="Papua New Guinea">Papua New Guinea</option>
                            <option value="Paracel Islands">Paracel Islands</option>
                            <option value="Paraguay">Paraguay</option>
                            <option value="Peru">Peru</option>
                            <option value="Philippines">Philippines</option>
                            <option value="Pitcairn Islands">Pitcairn Islands</option>
                            <option value="Poland">Poland</option>
                            <option value="Portugal">Portugal</option>
                            <option value="Puerto Rico">Puerto Rico</option>
                            <option value="Qatar">Qatar</option>
                            <option value="Reunion">Reunion</option>
                            <option value="Republic of the Congo">Republic of the Congo</option>
                            <option value="Romania">Romania</option>
                            <option value="Russia">Russia</option>
                            <option value="Rwanda">Rwanda</option>
                            <option value="Saint Helena">Saint Helena</option>
                            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                            <option value="Saint Lucia">Saint Lucia</option>
                            <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                            <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                            <option value="Samoa">Samoa</option>
                            <option value="San Marino">San Marino</option>
                            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                            <option value="Saudi Arabia">Saudi Arabia</option>
                            <option value="Senegal">Senegal</option>
                            <option value="Serbia">Serbia</option>
                            <option value="Seychelles">Seychelles</option>
                            <option value="Sierra Leone">Sierra Leone</option>
                            <option value="Singapore">Singapore</option>
                            <option value="Slovakia">Slovakia</option>
                            <option value="Slovenia">Slovenia</option>
                            <option value="Solomon Islands">Solomon Islands</option>
                            <option value="Somalia">Somalia</option>
                            <option value="South Africa">South Africa</option>
                            <option value="South Georgia and the South Sandwich Islands">South Georgia and the South Sandwich Islands</option>
                            <option value="South Korea">South Korea</option>
                            <option value="Spain">Spain</option>
                            <option value="Spratly Islands">Spratly Islands</option>
                            <option value="Sri Lanka">Sri Lanka</option>
                            <option value="Sudan">Sudan</option>
                            <option value="Suriname">Suriname</option>
                            <option value="Svalbard">Svalbard</option>
                            <option value="Swaziland">Swaziland</option>
                            <option value="Sweden">Sweden</option>
                            <option value="Switzerland">Switzerland</option>
                            <option value="Syria">Syria</option>
                            <option value="Taiwan">Taiwan</option>
                            <option value="Tajikistan">Tajikistan</option>
                            <option value="Tanzania">Tanzania</option>
                            <option value="Thailand">Thailand</option>
                            <option value="Togo">Togo</option>
                            <option value="Tokelau">Tokelau</option>
                            <option value="Tonga">Tonga</option>
                            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                            <option value="Tromelin Island">Tromelin Island</option>
                            <option value="Tunisia">Tunisia</option>
                            <option value="Turkey">Turkey</option>
                            <option value="Turkmenistan">Turkmenistan</option>
                            <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                            <option value="Tuvalu">Tuvalu</option>
                            <option value="Uganda">Uganda</option>
                            <option value="Ukraine">Ukraine</option>
                            <option value="United Arab Emirates">United Arab Emirates</option>
                            <option value="United Kingdom">United Kingdom</option>
                            <option value="USA">USA</option>
                            <option value="Uruguay">Uruguay</option>
                            <option value="Uzbekistan">Uzbekistan</option>
                            <option value="Vanuatu">Vanuatu</option>
                            <option value="Venezuela">Venezuela</option>
                            <option value="Viet Nam">Viet Nam</option>
                            <option value="Virgin Islands">Virgin Islands</option>
                            <option value="Wake Island">Wake Island</option>
                            <option value="Wallis and Futuna">Wallis and Futuna</option>
                            <option value="West Bank">West Bank</option>
                            <option value="Western Sahara">Western Sahara</option>
                            <option value="Yemen">Yemen</option>
                            <option value="Yugoslavia">Yugoslavia</option>
                            <option value="Zambia">Zambia</option>
                            <option value="Zimbabwe">Zimbabwe</option>
                        </select>
                    </span>

                </div>

            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                <div class="frm-left">Message: </div>
                <div class="frm-right">
                    <span class="wpcf7-form-control-wrap message">
                        <textarea  cols="10" formControlName="message" [ngClass]="(submitted && f.message.errors) ? 'req-textbox' : '' "></textarea>
                    </span>

                </div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <input type="submit" value="Send" class="btn btn-primary bg-blue custom-btn">&nbsp;
                <input type="button" value="Cancel" class="btn btn-secondary custom-btn" (click)="showInquiryform = false">
            </div>

        </div>
    </form>

</div>
