import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomService } from '../custom.service';
import { Router } from '@angular/router'
@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
    sideform : any;
    submitted : boolean = false;
    showSuccess : boolean = false;
    showError : boolean = false;
    showInquiryform : boolean = false;
    constructor(
        public  fb : FormBuilder,
        public customservice : CustomService,
        public router : Router
    ) {

    }


    ngOnInit() {

        this.sideform = this.fb.group({
            companyname   : ['', Validators.required],
            contactperson : ['', Validators.required],
            phone         : ['', Validators.required],
            email         : ['', [Validators.required,Validators.email]],
            country       : ['', Validators.required],
            message       : ['', Validators.required],
        })
    }
    get f(){
        return this.sideform.controls;
    }
    onSubmit(){

        this.submitted = true;
        if(this.sideform.invalid){
            return false;
        }

        this.customservice.sendEnquiry(this.sideform.value).subscribe(
            (data : any)=>{
                if(data == 1){
                    this.showInquiryform = false;
                    this.router.navigate(['/thankyou']);
                    //this.showSuccess = true;
                }
            },(error : any)=>{
                this.showError = true;
            }
        )

        this.sideform.reset();
        setTimeout(() => {
            this.showSuccess = false;
        }, 5000);

        Object.keys(this.sideform.controls).forEach(key => {
            this.sideform.get(key).setErrors(null);
        });

    }
}
