import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomService } from '../custom.service';
import { environment } from '../../environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SeoService } from '../seo.service';

@Component({
    selector: 'app-pages',
    templateUrl: './pages.component.html',
    styleUrls: ['./pages.component.css']
})
export class PagesComponent implements OnInit {
    PageId : any;
    PageDetail : any;
    availablePageDetail : boolean = false;
    env : any;
    PageDesc : any;

    seoArray = [];

    constructor(
        public route : ActivatedRoute,
        public customservice : CustomService,
        public sanitizer : DomSanitizer,
        public seoService: SeoService
    ) {
        this.env = environment;
        this.route.data.subscribe(
            (data)=>{
                this.PageId = data.PageId;
                this.fetchPageDetail(this.PageId);
            }
        )
    }

    ngOnInit() {

    }

    fetchPageDetail(PageId){
        this.customservice.getPageDetail(PageId).subscribe(
            (data : any)=>{
                if(data.PageInfo.results.length > 0){

                    this.seoArray.push(
                        {name: 'description', content:data.PageInfo.results[0].MetaDesc},
                        {name: 'keywords', content: data.PageInfo.results[0].Keywords}
                    )
                    this.seoService.setTitle(data.PageInfo.results[0].MetaTitle);
                    this.seoService.addMetaTags(this.seoArray);
                    this.seoService.createLinkForCanonicalURL();
                    this.PageDetail = data.PageInfo.results[0];
                    this.PageDesc = this.sanitizer.bypassSecurityTrustHtml(this.PageDetail.PageDesc);
                    this.availablePageDetail = true;
                }

            },(error : any)=>{
                console.log('Oops,there is some error.please try again.');
            }
        );
    }

}
