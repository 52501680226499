import { Component, OnInit } from '@angular/core';
import { CustomService } from '../custom.service';
import { environment } from '../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { SeoService } from '../seo.service';


// end

@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
    env : any;
    products : any ;
    Banner : any;
    availableProducts : boolean = false;
    availableBanner : boolean = false;
    CategoryId : any;
    availableCategoryProducts : boolean = false;


    CertificateGhosts = new Array(0);
    TradeFair = new Array(0);


    seoArray = [];

    constructor(
        public customservice : CustomService,
        public router : ActivatedRoute,
        public seoService: SeoService
    ) {
        this.env = environment;

        this.router.data.subscribe(
            (data :any)=>{
                this.CategoryId = data.CategoryId;
            }
        )

        if(this.CategoryId){
            this.getCategorywiseProducts(this.CategoryId);
        }else{
            this.getAllProducts();
        }


    }
    ngOnInit() {

    }

    getAllProducts(){



        this.customservice.getProducts().subscribe(
             (data : any)=>{
                 if(data.products.results.length > 0){
                     this.products = data.products.results;
                     this.availableProducts = true;
                 }
             },(error)=>{
                 console.log(error)
             }
         )
         this.customservice.getPageDetail(8).subscribe(
             (data : any)=>{
                 if(data.PageInfo.results.length > 0){
                     this.Banner = data.PageInfo.results[0].PageBanner;
                     this.availableBanner = true;
                 }
             },(error)=>{
                 console.log(error)
             }
         )
    }

    getCategorywiseProducts(CategoryId){


         this.customservice.getProductsbyCategoryId(CategoryId).subscribe(
             (data :any)=>{
                 if(data.catproducts.length > 0){
                     this.products = data.catproducts;
                     this.availableCategoryProducts = true;
                 }
                 if(data.categoryinfo[0].CategoryBanner){
                     this.Banner = data.categoryinfo[0].CategoryBanner;
                     this.availableBanner = true;
                     this.seoArray.push(
                        {name: 'description', content: data.categoryinfo[0].MetaDesc},
                        {name: 'keywords', content:  data.categoryinfo[0].Keywords}
                    )
                    this.seoService.setTitle( data.categoryinfo[0].MetaTitle);
                    this.seoService.addMetaTags(this.seoArray);
                    this.seoService.createLinkForCanonicalURL();

                 }

             },(error :any)=>{
                 console.log("Oops,there is some error.please try again");
             }
         )
    }

}
