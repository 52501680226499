<div  *ngIf="availableproduct"><img  width="1423" height="240" *ngIf="availableproduct && productdetial.HeaderBanner" src="{{ env.ProductBanner + productdetial.HeaderBanner }}" class="w-100"/>

<div class="single-post-row">

    <div class="container">
        <div class="row">
            <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12 b-right">
                <h1 class="entry-title" *ngIf="availableproduct">{{ productdetial.ProductName }}</h1>

                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="availableproduct">
                        <div [innerHTML]="productdetial.ProductSmallDesc"></div>
                        <!-- Short Description -->
                        <!-- <div class="entry-content">
                            <p>We offer Golden Yellow Sesame Seeds, which are grown by seasoned farmers with their well learnt expertise. These seeds are thoroughly cleaned and processed at our facility with the help of advanced and cutting edge machinery.</p><p> Our quality conscious team ensures that the product reaches customers in 99% pristine and pure state, and retains all its flavors and nutritional value.</p>
                        </div> -->
                        <!-- End -->
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <header class="entry-header" *ngIf="availableproduct">
                            <div id="post_image">
                                <img alt="{{ productdetial.ImageAlt }}" src="{{ env.ProductImage + productdetial.ProductImage }}">
                            </div>
                        </header>
                    </div>
                </div>

                <!-- Product Description -->

                <div *ngIf="availableproduct" [innerHTML]="productdetial.ProductDesc"></div>


                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <nav class="navigation post-navigation" role="navigation">
                            <h1 class="screen-reader-text">Post navigation</h1>
                            <div class="nav-links">
                                <a routerLink="{{ '/' + previousSlug }}" rel="next" *ngIf="previousSlug">
                                    <span class="meta-nav"><i class="fa fa-arrow-left"></i></span> {{ previousTitle }}
                                </a>
                                <a routerLink="{{ '/' + nextSlug }}" rel="prev" *ngIf="nextSlug">
                                    {{nextTitle }}
                                    <span class="meta-nav"><i class="fa fa-arrow-right"></i></span>
                                </a>
                            </div>
                        </nav>
                    </div>
                </div>

            </div>

            <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-xs-fullwidth" *ngIf="availableProducts">
                <div class="cat_title">Product</div>
                <ul class="lcp_catlist" id="lcp_instance_0">
                    <li *ngFor="let p of products">
                        <a routerLink="{{ '/' + p.ProductSlug }}" title="Hulled Sesame Seeds">{{ p.ProductName }}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
</div>
