let URL  =  'https://manage.shyamind.com/';
export const environment = {
    production        : true,
    Api               : URL + 'api/gateway/',
    BannerUrl         : URL + 'uploads/banners/',
    ProductImage      : URL + 'uploads/products/',
    ProductBanner     :  URL + 'uploads/productbanners/',
    CertificatesImage : URL + 'uploads/certificates/',
    PageBannerImage   : URL + 'uploads/cmsbanners/',
    EventImages       : URL + 'uploads/newsandevents/',
    CategorieBanner   : URL + 'uploads/categories/',
};
