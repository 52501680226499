import { Component, OnInit } from '@angular/core';
import { CustomService } from '../custom.service';
import { environment } from '../../environments/environment';
import { SeoService } from '../seo.service';


@Component({
    selector: 'app-trade-fair',
    templateUrl: './trade-fair.component.html',

    styleUrls: ['./trade-fair.component.css']
})
export class TradeFairComponent implements OnInit {
    env : any;
    NewEvents : any = [];
    OldEvents : any = [];

    ghosts = new Array(0);
    CertificateGhosts = new Array(0);
    TradeFair = [];

    seoArray = [];

    constructor(
        public customservice : CustomService,
        public seoService: SeoService
    ) {
        this.env = environment;
    }

    ngOnInit() {

        // seo section
        this.customservice.getPageDetail(11).subscribe(
            (data : any)=>{
                this.seoArray.push(
                    {name: 'description', content:data.PageInfo.results[0].MetaDesc},
                    {name: 'keywords', content: data.PageInfo.results[0].Keywords}
                )
                this.seoService.setTitle(data.PageInfo.results[0].MetaTitle);
                this.seoService.addMetaTags(this.seoArray);
                this.seoService.createLinkForCanonicalURL();
            },(error:any)=>{
                console.log("Oops,there is some error,please try again.");
            }
        )
        // end


         this.customservice.getEvents().subscribe(
             (data : any)=>{
                 if(data.all_newsandevents.results.length > 0){

                    for(let e=0;e<data.all_newsandevents.results.length;e++){
                         if(data.all_newsandevents.results[e].EventType == '1'){
                             this.NewEvents.push(data.all_newsandevents.results[e]);
                         }else{
                             this.OldEvents.push(data.all_newsandevents.results[e]);
                         }
                     }
                 }
             },(error : any)=>{
                 console.log("Oops,there is some error.please try again.");
             }
         );

    }

}
