import { Component, OnInit,ViewChild } from '@angular/core';
import { CustomService } from '../custom.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
    availableCategory : boolean = false;
    AllCategory : any;
    mobileNav: boolean = false;
    @ViewChild('dismiss1') closenav: any;

    constructor(
        public customservice : CustomService
    ) {
    }

    ngOnInit() {

        this.customservice.getCategories().subscribe(
            (data : any)=>{

                if(data.allcategories.results.length > 0){
                    this.AllCategory = data.allcategories.results;
                    this.availableCategory = true;
                }

            },(error :any)=>{
                console.log("Oops,there is some error.please try again");
            }
        )
    }

    closemenu(){
        this.closenav.nativeElement.click();
    }

}
