<div class="container">
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <header class="about-entry-header">
                <h1 class="about-entry-title">Career</h1>
            </header>
        </div>
    </div>
    <div class="row">
        <div class="col-md-5" style="padding:0">
            <form [formGroup]="carrerForm" (ngSubmit)="onSubmit()"  method="post">
                <div class="contact-frm">
                    <div class="alert alert-success" *ngIf="showSuccess">
                        <strong>Thank you!</strong> we will contact you shortly.
                    </div>
                    <div class="alert alert-danger" *ngIf="showError">
                        <strong>Error!</strong> Oops,some error.please try again.
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="frm-left">Name* </div>
                        <div class="frm-right">
                            <span class="wpcf7-form-control-wrap company-name">
                                <input type="text" formControlName="personname"  value="" size="40" required>
                            </span>
                            <div *ngIf="submitted && f.personname.errors" class="invalid-feedback">
                                <div *ngIf="f.personname.errors.required">Person name is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="frm-left">Email* </div>
                        <div class="frm-right">
                            <span class="wpcf7-form-control-wrap company-name">
                                <input type="text" formControlName="email"  value="" size="40" required>
                            </span>
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">Email is required</div>
                                <div *ngIf="f.email.errors.email">Email address is invalid</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="frm-left">Applying for the Post* </div>
                        <div class="frm-right">
                            <span class="wpcf7-form-control-wrap company-name">
                                <input type="text" formControlName="applypost"  value="" size="40" required>
                            </span>
                            <div *ngIf="submitted && f.applypost.errors" class="invalid-feedback">
                                <div *ngIf="f.applypost.errors.required">Applying for the Post is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="frm-left">Contact Number* </div>
                        <div class="frm-right">
                            <span class="wpcf7-form-control-wrap company-name">
                                <input type="text" formControlName="contactnumber"  value="" size="40" required>
                            </span>
                            <div *ngIf="submitted && f.contactnumber.errors" class="invalid-feedback">
                                <div *ngIf="f.contactnumber.errors.required">Contact number is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="frm-left">Upload Your Resume* </div>
                        <div class="frm-right">
                            <span class="wpcf7-form-control-wrap company-name">
                                <input type="file" formControlName="uploadresume"  (change)="uploadFile($event.target.files)" required>
                            </span>
                            <div  *ngIf="uploadError">
                                <div innerHtml="{{ uploaderrormsg }}" style="color:#FF0000"></div>
                            </div>
                            <div *ngIf="submitted && f.uploadresume.errors" class="invalid-feedback">
                                <div *ngIf="f.uploadresume.errors.required">Please upload your resume.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="frm-left">Covering Text*: </div>
                        <div class="frm-right">
                            <span class="wpcf7-form-control-wrap message">
                                <textarea  cols="10" formControlName="coveringtext"></textarea>
                            </span>
                            <div *ngIf="submitted && f.coveringtext.errors" class="invalid-feedback">
                                <div *ngIf="f.coveringtext.errors.required">Covering Text is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                    <br>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <input type="submit" value="Send" class="btn btn-primary bg-blue custom-btn">&nbsp;
                        <input type="button" value="Reset" class="btn btn-secondary custom-btn" (click)="resetForm()">
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<br>
