<div class="container">

    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <header class="about-entry-header">
                <h1 class="about-entry-title">Trade Fair</h1>
            </header>
        </div>
    </div>

    <div>
        <!-- Event with phtoes -->
        <div class="row tf" *ngFor="let ef of NewEvents">
            <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 events">
                <picture class="img-responsive">
                    <source type="image/webp" srcset="{{env.EventImages + ef.EventImage}}.webp" class="img-responsive">
                    <img class="img-responsive" src="{{env.EventImages + ef.EventImage}}">
                </picture>
                <h3 class="text-center">{{ ef.EventTitle }}<br>{{ ef.Eventdate }}</h3>
                <p class="text-center"[innerHTML]= "ef.EventLocation"></p>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-6 col-xs-12 events">
                <owl-carousel  [options]="{items: 1, dots: true, navigation: false, autoplay : true, loop : true}" [carouselClasses]="['owl-theme', 'sliding']">
                    <div class="item" *ngFor="let p of ef.Photoes">
                        <picture class="img-responsive">
                            <source type="image/webp" srcset="{{env.EventImages + p.ImageName}}.webp" class="img-responsive">
                            <img class="img-responsive" src="{{env.EventImages + p.ImageName}}">
                        </picture>
                    </div>
                </owl-carousel>
            </div>

        </div>
        <!-- End -->
        <!-- Event with no photoes loop start -->
        <div class="row tf nophotoes">
            <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 events" *ngFor="let ne of OldEvents">
                <picture class="img-responsive">
                    <source type="image/webp" srcset="{{env.EventImages + ne.EventImage}}.webp" class="img-responsive">
                    <img class="img-responsive" src="{{env.EventImages + ne.EventImage}}">
                </picture>
                <h3 class="text-center">{{ ne.EventTitle }}<br>{{ ne.Eventdate }}</h3>
                <p class="text-center" [innerHTML]= "ne.EventLocation"></p>
            </div>
        </div>
        <!-- End -->
    </div>


</div>
