import { Component, OnInit } from '@angular/core';
import { CustomService } from '../custom.service';
import { environment } from '../../environments/environment';
import { SeoService } from '../seo.service';



@Component({
    selector: 'app-certificates',
    templateUrl: './certificates.component.html',

    styleUrls: ['./certificates.component.css']
})

export class CertificatesComponent implements OnInit {
    env : any;
    AllCertificates : any;
    availableCertificates : any;

    ghosts = new Array(0);
    CertificateGhosts = [];
    TradeFair = new Array(0);

    seoArray = [];


    constructor(
        public customservice : CustomService,
        public seoService: SeoService
    ) {
        this.env = environment;

    }

    ngOnInit() {

        // seo section

        this.customservice.getPageDetail(10).subscribe(
            (data : any)=>{
                this.seoArray.push(
                    {name: 'description', content:data.PageInfo.results[0].MetaDesc},
                    {name: 'keywords', content: data.PageInfo.results[0].Keywords}
                )
                this.seoService.setTitle(data.PageInfo.results[0].MetaTitle);
                this.seoService.addMetaTags(this.seoArray);
                this.seoService.createLinkForCanonicalURL();
            },(error:any)=>{
                console.log("Oops,there is some error,please try again.");
            }
        )
        // end




        this.customservice.getCertificates().subscribe(
             (data :any)=>{

                 if(data.all_certificates.results.length > 0){
                     this.AllCertificates = data.all_certificates.results;
                     this.availableCertificates = true;
                 }
                 console.log(this.AllCertificates);
             },(error:any)=>{
                 console.log("Oops there is some error.please try again.");
             }
         )
    }

}
