import { Injectable, Inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Title }     from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class SeoService {

    constructor(
        private meta: Meta,
        private titleService: Title,
        @Inject(DOCUMENT) private doc:any


    ) {

    }

    addMetaTags(seoArray){
        this.meta.addTags(seoArray);
    }
    setTitle(newTitle: string){
        this.titleService.setTitle(newTitle);
    }
    public createLinkForCanonicalURL() {
        
        const head = this.doc.getElementsByTagName('head')[0];
        var element: HTMLLinkElement= this.doc.querySelector(`link[rel='canonical']`) || null
        if (element==null) {
        element= this.doc.createElement('link') as HTMLLinkElement;
        head.appendChild(element);
        }
        var url = this.doc.URL;
        url = url.replace("http://127.0.0.1:4005", "https://www.shyamind.com" );
        url = url.replace('/index.html', '');
        element.setAttribute('rel','canonical')
        element.setAttribute('href',url);
    }
}
